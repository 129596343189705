import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import api from "../../../../../services/api";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { useTheme } from "../../../../../hooks/theme";
import { useCompanies } from "../../../../../hooks/companies"; // Importa o hook useCompanies
import { useLegend } from "../../../../../hooks/legend"; // Ajuste o caminho conforme necessário
import ModalFilters from "../../../../../components/CustomModalFilters";

export default function ChartFretePorUF() {
  const { darkMode } = useTheme();
  const { empresa, transportadora } = useCompanies(); // Obtém as seleções de empresa e transportadora
  const { legendMode } = useLegend();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const color = darkMode ? "#e9ecef" : "#1e1e2f";
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);

        const params = {
          dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
          dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
        };

        if (empresa) {
          params.embarcadores = [empresa.value]; // Adiciona o ID da empresa como array
        }

        if (transportadora) {
          params.transportadoras = [transportadora.value]; // Adiciona o ID da transportadora como array
        }

        const response = await api.get("/dashboard/custo/frete-por-uf", {
          params,
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    if (empresa) {
      loadCharts(); // Carrega os gráficos quando empresa ou transportadora mudar
    }
  }, [auxDtFinal, auxDtInicial, empresa, transportadora]); // Adiciona empresa e transportadora como dependências

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;
      const payFormatted = payload.map((pay) => ({
        ref: "UF",
        sigla: pay.payload.sigla,
        name: "Valor:",
        value: formatCurrency(pay.payload.valor),
      }));
      return (
        <div
          className="tooltip-inner"
          style={{
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].ref}: `}</strong>
            {payFormatted[0].sigla}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].name}: `}</strong>
            {payFormatted[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const offset = 10; // Ajuste conforme necessário
    return (
      <text
        x={x + width / 2}
        y={y - offset}
        fill={darkMode ? "#FFFFFF" : "#333333"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {formatCurrency(value)}
      </text>
    );
  };

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <Card
      style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
      className="card-chart"
    >
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <h4>Frete por UF</h4>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              className="btn-sm"
              color="info"
              onClick={toggleModalFilters}
            >
              Período
            </Button>
          </Col>
        </Row>
        <CardTitle>Custo do frete por Estado dos últimos 3 meses</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <Row className="justify-content-center">
            <Col>
              <ResponsiveContainer width="100%" height={250}>
                {data.length > 0 ? (
                  <BarChart
                    data={data}
                    margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
                  >
                    <defs>
                      <linearGradient id="bar1" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="20%"
                          stopColor="#2dce89"
                          stopOpacity={0.3}
                        />
                        <stop
                          offset="90%"
                          stopColor="#2dce89"
                          stopOpacity={0.1}
                        />
                        <stop
                          offset="90%"
                          stopColor="#2dce89"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid
                      stroke="rgba(45,206,137, 0.1)"
                      strokeWidth={1}
                    />
                    <XAxis
                      dataKey="sigla"
                      stroke={color}
                      style={{ fontWeight: 100 }}
                    />
                    <YAxis
                      tickFormatter={(tick) => formatCurrency(tick)}
                      stroke={color}
                      style={{ fontWeight: 100 }}
                      width={100}
                    />
                    <Tooltip content={(props) => CustomTooltip(props)} />
                    <Legend />
                    <Bar
                      name="Frete por UF"
                      dataKey="valor"
                      type="monotone"
                      stroke="#10e211"
                      fillOpacity={1}
                      fill="url(#bar1)"
                    >
                      {legendMode && (
                        <LabelList dataKey="valor" content={<CustomLabel />} />
                      )}
                    </Bar>
                  </BarChart>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <h5>Nenhum dado para apresentar!</h5>
                  </div>
                )}
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
