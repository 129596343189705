import React from "react";
import { ClipLoader } from "react-spinners";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import { formatCurrency } from "../../utils/formatCurrency";
import { weightFormat } from "../../utils/weightFormat";
import { percentFormat } from "../../utils/percentFormat";
import { unitFormat } from "../../utils/unitFormat";
import { useTheme } from "../../hooks/theme";
import { useLegend } from "../../hooks/legend";

export default function CustomChartArea({
  data,
  name,
  dataKey,
  grid,
  lineColor,
  measure,
  yAxisWidth,
  loading,
  idLinha,
}) {
  const { darkMode } = useTheme();
  const { legendMode } = useLegend(); // Pega o estado do hook para controle da exibição

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;

      if (!payload) {
        return null;
      }

      let valueFormatted = 0;
      if (measure === "R$") valueFormatted = formatCurrency(payload[0].value);
      if (measure === "KG") valueFormatted = weightFormat(payload[0].value);
      if (measure === "%") valueFormatted = percentFormat(payload[0].value);
      if (measure === "UN") valueFormatted = percentFormat(payload[0].value);
      return (
        <div
          className="tooltip-inner"
          style={{
            background: darkMode ? "#e9ecef" : "#1e1e2f",
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p style={{ color: darkMode ? "#000" : "#fff", fontWeight: 100 }}>
            <strong>{`${payload[0].name}: `}</strong>
            {valueFormatted}
          </p>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader sizeUnit="px" size={90} color="#1d8cf8" loading={loading} />
        <br />
        <br />
        <h1 className="text-info" style={{ textAlign: "center" }}>
          Carregando ...
        </h1>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
      >
        <defs>
          <linearGradient id={idLinha} x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor={lineColor} stopOpacity={0.3} />
            <stop offset="95%" stopColor={lineColor} stopOpacity={0} />
            <stop offset="95%" stopColor={lineColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="periodo"
          stroke={darkMode ? "#fff" : "#000"}
          style={{ fontWeight: 100 }}
        />
        <YAxis
          tickFormatter={(tick) => {
            if (measure === "R$") return formatCurrency(tick);
            if (measure === "KG") return weightFormat(tick);
            if (measure === "%") return percentFormat(tick);
            if (measure === "UN") return unitFormat(tick);
            return tick;
          }}
          stroke={darkMode ? "#fff" : "#000"}
          style={{ fontWeight: 100 }}
          width={yAxisWidth}
        />
        <CartesianGrid
          stroke={darkMode ? "rgba(255, 214, 0, 0.1)" : "rgba(0, 0, 0, 0.1)"}
          strokeWidth={1}
        />
        <Tooltip content={(props) => CustomTooltip(props)} />
        <Legend />
        <Area
          name={name}
          type="monotone"
          dataKey={dataKey}
          stroke={lineColor}
          strokeWidth={2}
          fillOpacity={1}
          activeDot={{ r: 8 }}
          dot
          fill={`url(#${idLinha})`}
        >
          {legendMode && ( // Apenas renderiza os labels se legendMode for verdadeiro
            <LabelList
              dataKey={dataKey}
              position="top"
              offset={10} // Ajuste conforme necessário
              formatter={(value) => {
                if (measure === "R$") return formatCurrency(value);
                if (measure === "KG") return weightFormat(value);
                if (measure === "%") return percentFormat(value);
                if (measure === "UN") return unitFormat(value);
                return value;
              }}
              fill={darkMode ? "#fff" : "#000"} // Ajusta a cor do texto baseado no tema
            />
          )}
        </Area>
      </AreaChart>
    </ResponsiveContainer>
  );
}
