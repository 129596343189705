import React, { useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// custo
import ChartTotalCusto from "./componentsCusto/ChartTotalCusto";
import ChartTotalizadores from "./componentsCusto/ChartTotalizadores";
import ChartFretePorKg from "./componentsCusto/ChartFretePorKg";
import ChartFretePorNf from "./componentsCusto/ChartFretePorNf";
import ChartContaFrete from "./componentsCusto/ChartContaFrete";
import ChartFretePorUF from "./componentsCusto/ChartFretePorUF";
import ChartStatusCte from "./componentsCusto/ChartStatusCte";
import ChartStatusDiv from "./componentsCusto/ChartStatusDiv";
import ChartStatusCtePago from "./componentsCusto/ChartStatusCtePago";
import ChartFreteClienteUF from "./componentsCusto/ChartFreteClienteUF";
import ChartFretePorTransp from "./componentsCusto/ChartFretePorTransp";
import ChartFretePorNegocio from "./componentsCusto/ChartFretePorNegocio";
import ChartMediaFreteUF from "./componentsCusto/ChartMediaFreteUF";
import ChartMediaFreteTransp from "./componentsCusto/ChartMediaFreteTransp";
import ChartFretePorUfNF from "./componentsCusto/ChartFretePorUfNF";
import ChartFretePorTranspNF from "./componentsCusto/ChartFretePorTranspNF";

// performance
import ChartOcorrenciasTratadas from "./componentsPerformance/ChartOcorrenciasTratadas";
import ChartOcorrenciasEmAberto from "./componentsPerformance/ChartOcorrenciasEmAberto";
import ChartFreteAdicional from "./componentsPerformance/ChartFreteAdicional";
import ChartStatusEntrega from "./componentsPerformance/ChartStatusEntrega";
import ChartDificuldadesEntrega from "./componentsPerformance/ChartDificuldadesEntrega";
import ChartMediaMensal from "./componentsCusto/ChartMediaMensal";
import ChartEntregaPorTransp from "./componentsPerformance/ChartEntregaPorTransp";
import ChartPrazoPorTransp from "./componentsPerformance/ChartPrazoPorTransp";
import ChartEntregaPorCentroCusto from "./componentsPerformance/ChartEntregaPorCentroCusto";
import ChartTotalCustoTransp from "./componentsPerformance/ChartTotalCustoTransp";

// cockpit
import ChartIntegracao from "./componentesCockpit/ChartIntegracao";
import ChartIntegracaoFat from "./componentesCockpit/ChartIntegracaoFat";
import ChartIntegracaoCte from "./componentesCockpit/ChartIntegracaoCte";
import ChartIntegracaoNfe from "./componentesCockpit/ChartIntegracaoNfe";

// auditoria
import ChartFaturasAuditoria from "./componentsAuditoria/ChartFaturasAuditoria";
import ChartFaturaSituacao from "./componentsAuditoria/ChartFaturaSituacao";
import CalendarFatura from "./componentsAuditoria/CalendarFatura";
import ChartFaturaPorTransp from "./componentsAuditoria/ChartFaturaPorTransp";
import ChartFaturasResumo from "./componentsAuditoria/ChartFaturasResumo";
import KanbanStatusFatura from "./componentsAuditoria/KanbanStatusFatura";
import ChartResumoAuditoria from "./componentsAuditoria/ChartResumoAuditoria";

export default function Dashboard() {
  const [tab, setTab] = useState("custo");

  const changeActiveTab = (e, tadName) => {
    setTab(tadName);
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Nav className="nav-pills-info" pills>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={tab === "custo" ? "active" : ""}
                onClick={(e) => changeActiveTab(e, "custo")}
              >
                Custos de Frete
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={tab === "performance" ? "active" : ""}
                onClick={(e) => changeActiveTab(e, "performance")}
              >
                Performance de Entrega
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={tab === "integracao" ? "active" : ""}
                onClick={(e) => changeActiveTab(e, "integracao")}
              >
                Integração
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={tab === "auditoria" ? "active" : ""}
                onClick={(e) => changeActiveTab(e, "auditoria")}
              >
                Auditoria de Frete
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="tab-space" activeTab={tab}>
            <TabPane tabId="custo">
              <ChartTotalCusto />
              <ChartTotalizadores />
              <Row>
                <Col>
                  <ChartFretePorKg />
                </Col>
                <Col>
                  <ChartFretePorNf />
                </Col>
                <Col>
                  <ChartContaFrete />
                </Col>
              </Row>
              <ChartMediaMensal />
              <Row>
                <Col>
                  <ChartFretePorUF />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ChartStatusCte />
                </Col>
                <Col>
                  <ChartStatusDiv />
                </Col>
                <Col>
                  <ChartStatusCtePago />
                </Col>
              </Row>
              <ChartFreteClienteUF />
              {/* <ChartFretePorTransp /> */}
              <Row>
                <Col md={6}>
                  <ChartFretePorTransp />
                </Col>
                <Col md={6}>
                  <ChartFretePorNegocio />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ChartMediaFreteUF />
                </Col>
                <Col md={6}>
                  <ChartMediaFreteTransp />
                </Col>
              </Row>
              <ChartFretePorUfNF />
              <ChartFretePorTranspNF />
            </TabPane>
            <TabPane tabId="performance">
              <ChartEntregaPorCentroCusto />
              <Row>
                <Col md={12}>
                  <ChartEntregaPorTransp />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ChartPrazoPorTransp />
                </Col>
              </Row>
              <ChartTotalizadores />
              <Row>
                <Col md={6}>
                  <ChartOcorrenciasTratadas />
                </Col>
                <Col md={6}>
                  <ChartOcorrenciasEmAberto />
                </Col>
              </Row>
              <ChartFreteAdicional />
              <Row>
                <Col md={6}>
                  <ChartStatusEntrega />
                </Col>
                <Col md={6}>
                  <ChartDificuldadesEntrega />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="integracao">
              <ChartIntegracao />
              <ChartIntegracaoFat />
              <ChartIntegracaoCte />
              <ChartIntegracaoNfe />
            </TabPane>
            <TabPane tabId="auditoria">
              <ChartFaturasAuditoria />
              <ChartFaturasResumo />
              <Row>
                <Col md={6}>
                  <ChartFaturaSituacao />
                </Col>
                <Col md={6}>
                  <ChartFaturaPorTransp />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ChartResumoAuditoria />
                </Col>
              </Row>
              <CalendarFatura />
              <KanbanStatusFatura />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
}
